import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SessionPopupComponent } from '../components/session-popup/session-popup.component'; // Import your pop-up component

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private dialogRef: MatDialogRef<SessionPopupComponent> | null = null;

  constructor(private dialog: MatDialog) {}

  openUnauthorizedDialog(): void {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(SessionPopupComponent, {
        width: '600px',
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.dialogRef = null;
      });
    }
  }
}
