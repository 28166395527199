import { Injectable, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import {DataManagerService} from '../data/services/DataManager/data-manager.service';
import {AppConstants} from '../shared/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class IdleService {
  private idleTimeout: any;
  private countdownTimeout: any;
  private idleTime = 15 * 60 * 1000; // 15 minutes
  private countdownTime = 2 * 60 * 1000; // 2 minutes
  private countdown$: Subject<number> = new Subject<number>();
  private logout$: Subject<void> = new Subject<void>();
  private resetIdle$: Subject<void> = new Subject<void>();


  constructor(private ngZone: NgZone, private dataManager: DataManagerService) {
   // console.log('### IdleService created');
    this.init();
  }

  private init() {
    this.resetIdleTimer();

    // Sync idle timer reset across tabs
    window.addEventListener('storage', (event) => {
      if (event.key === 'apiActivity') {
        this.resetIdleTimer();
      }
    });
  }

  // Method to be called from the interceptor on API call
  resetIdleTimer() {
    //console.log('### Resetting idle timer, note that there is call to localStorage.setItem synchronisation ');
    localStorage.setItem('apiActivity', new Date().toISOString());
    this.resetIdle$.next();
    this.ngZone.runOutsideAngular(() => {
      clearTimeout(this.idleTimeout);
      clearTimeout(this.countdownTimeout);

      // Set timeout for 13 minutes to start the countdown
      this.idleTimeout = setTimeout(() => {
        this.startCountdown();
      }, this.idleTime - this.countdownTime);
    });
  }

  private startCountdown() {

    let timeLeft = this.countdownTime / 1000;
    //console.log('### Starting countdown',timeLeft);
    this.countdownTimeout = setInterval(() => {
      timeLeft--;
      this.countdown$.next(timeLeft);
      //console.log('### Time left:', timeLeft);
      if (timeLeft <= 0) {
        clearInterval(this.countdownTimeout);
        this.ngZone.run(() => this.logout());
      }
    }, 1000);
  }

  private logout() {
    this.logout$.next();
    this.dataManager.logOut();
  }
  cancelLogout() {
    clearTimeout(this.idleTimeout);
    clearTimeout(this.countdownTimeout);
    // ???? this.resetIdleTimer(); // Resets the timer again
    console.log('### Logout cancelled');
    this.ngZone.run(() => this.fetchRandomApi());
  }
  fetchRandomApi() {// Resets the timer in backend
    const  getSettingUrl =  AppConstants.BASE_URL + AppConstants.SERVICE_NAME_ACCOUNT_SETTINGS + '?limit=5';
    console.log('### Fetching random API to reset the timer',getSettingUrl);
    this.dataManager.getRequest(getSettingUrl, 0).subscribe(
      res => {
      },
      err => {
      }
    );
  }

  getCountdownObservable() {
    return this.countdown$.asObservable();
  }

  getLogoutObservable() {
    return this.logout$.asObservable();
  }

  getResetIdleObservable() {
    return this.resetIdle$.asObservable();
  }
}
