// auth.interceptor.ts
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog'; // Assuming you use Angular Material for pop-ups
// import { SessionPopupComponent } from '../components/session-popup/session-popup.component';
import { DialogService } from '../services/dialog.service'; // Adjust the path as needed
import { IdleService } from '../services/idle.service';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private dialogService: DialogService,private idleService: IdleService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.idleService.resetIdleTimer();
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        //in case of login from portal the login api fails with 401
        if (error.status === 401 && error.url.split('/').slice(-2).join('/') !== 'v1/logout' ) {
          // Open pop-up when a 401 error is intercepted
           this.dialogService.openUnauthorizedDialog()
        }
        return throwError(() => error);
      })
    );
  }
}
